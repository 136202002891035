import axios from 'axios';
import authHeader from './auth-header';
class Customer {
  findAll() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get('/management/customers', {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  async customerOrders({ sort, page, limit }) {
    try {
      const { data } = await axios.get(
        `/management/customerOrders?sort=${sort}&page=${page}&limit=${limit}`,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async customerProductOrders(customer_id) {
    try {
      const { data } = await axios.get(
        `/management/customerProductOrders?customerId=${customer_id}`,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
export default new Customer();
