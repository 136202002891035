<template>
  <div id="add-order">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="เพิ่มออเดอร์" />
            <v-form
              v-on:submit.prevent="submitAddOrder()"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <label for="">แอดมิน</label>
                    <v-autocomplete
                      v-model="order.admin_id"
                      :items="adminItems"
                      item-text="username"
                      item-value="id"
                      solo
                      dense
                      class="mt-2"
                      :rules="adminRule"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <label for="">ชื่อเพจ</label>
                    <v-autocomplete
                      v-model="order.page"
                      :items="pageItems"
                      solo
                      dense
                      class="mt-2"
                      :rules="adminRule"
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" md="7">
                    <div
                      class="d-flex align-center"
                      v-if="userPhoneStatus === 1"
                    >
                      <div style="width: 100%;">
                        <label for="">เบอร์โทรศัพท์ลูกค้าเดิม</label>
                        <v-autocomplete
                          v-model="oldCustomerObj"
                          :items="customerItems"
                          item-text="phone"
                          return-object
                          solo
                          dense
                          class="mt-2"
                          @change="selectOldCustomer()"
                          :rules="oldPhoneRule"
                          required
                        >
                        </v-autocomplete>
                      </div>
                      <div>
                        <v-btn
                          color="blue"
                          height="38px"
                          class="mt-1 ml-2"
                          @click.prevent="userPhoneStatus = 2"
                        >
                          เพิ่มลูกค้าใหม่
                        </v-btn>
                      </div>
                    </div>
                    <div
                      class="d-flex align-center"
                      v-if="userPhoneStatus === 2"
                    >
                      <div style="width: 100%;">
                        <label for="">เบอร์โทรศัพท์ลูกค้าใหม่</label>
                        <v-text-field
                          v-model="order.phone"
                          solo
                          dense
                          class="mt-2"
                          :rules="newPhoneRule"
                          required
                        ></v-text-field>
                      </div>
                      <div>
                        <v-btn
                          color="white"
                          height="38px"
                          outlined
                          class="mt-1 ml-2"
                          @click.prevent="userPhoneStatus = 1"
                        >
                          ลูกค้าเดิม
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <label for="">ชื่อ Facebook ลูกค้า</label>
                    <v-text-field
                      v-model="order.facebook_name"
                      solo
                      dense
                      class="mt-2"
                      :rules="facebookRule"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="pa-0"
                v-for="(product, index) in order.products"
                :key="index"
              >
                <v-row>
                  <v-col cols="12" md="5">
                    <label for="">สินค้าชิ้นที่ {{ index + 1 }}</label>
                    <v-autocomplete
                      v-model="order.products[index].product_id"
                      :items="productItems"
                      item-value="id"
                      item-text="product_name"
                      solo
                      dense
                      class="mt-2"
                      :rules="productRule"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="7" md="2">
                    <label for="">ราคา</label>
                    <v-text-field
                      v-model="order.products[index].price"
                      type="number"
                      solo
                      dense
                      class="mt-2"
                      :rules="priceRule"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" md="2">
                    <label for="">จำนวน</label>
                    <v-text-field
                      v-model="order.products[index].quantity"
                      type="number"
                      solo
                      dense
                      class="mt-2"
                      :rules="quantityRule"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label for="">รูปแบบสินค้า</label>
                    <v-select
                      v-model="order.products[index].product_type"
                      :items="productTypeItems"
                      solo
                      dense
                      class="mt-2"
                      :rules="productTypeRule"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="px-0 pt-0 pb-5">
                <v-btn
                  v-if="order.products.length > 1"
                  class="mr-4"
                  small
                  color="red"
                  outlined
                  @click.prevent="delProduct()"
                  >ลบ</v-btn
                >
                <v-btn
                  small
                  color="white"
                  outlined
                  @click.prevent="addProduct()"
                >
                  <v-icon left>mdi-plus</v-icon>
                  เพิ่มสินค้าชิ้นที่ {{ order.products.length + 1 }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="7" md="6">
                    <label for="">สถานะการชำระ</label>
                    <v-select
                      :items="paymentStatusItems"
                      item-text="text"
                      item-value="id"
                      solo
                      dense
                      class="mt-2"
                      v-model="order.payment_status_id"
                      @change="handleOutstandBalance()"
                      :rules="paymentStatusRule"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="5" md="6" v-if="order.payment_status_id === 1">
                    <label for="">ยอดชำระมัดจำ</label>
                    <v-text-field
                      v-model="order.deposit"
                      type="number"
                      solo
                      dense
                      class="mt-2"
                      @change="handleOutstandBalance()"
                      :rules="depositRule"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="7" md="6">
                    <label for="">มูลค่าออเดอร์</label>
                    <v-text-field
                      v-model="order.cost"
                      type="number"
                      solo
                      dense
                      @change="handleOutstandBalance()"
                      :rules="costRule"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" md="6" class="text-right">
                    <label for="">ค้างชำระ</label>
                    <p class="outstand-balance">
                      ฿{{ order.outstand_balance }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <label for="">หมายเหตุ</label>
                    <v-text-field
                      solo
                      dense
                      v-model="order.remark"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="mb-6">
                    <label for="">วันที่และเวลา</label>
                    <input
                      class="datetime-picker-input"
                      type="datetime-local"
                      v-model="order.created_date"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-right pa-0 mt-4">
                <v-btn
                  :loading="loading"
                  large
                  color="blue"
                  @click.prevent="submitAddOrder()"
                >
                  <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
                  บันทึกการเพิ่ม
                </v-btn>
                <v-btn class="ml-6" large outlined color="red">ยกเลิก</v-btn>
              </v-col>
            </v-form>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import HeaderPage from '@/components/HeaderPage.vue';
import FooterAdmin from '@/components/FooterAdmin';

import CustomerService from '@/services/Customer.service';
import AdminService from '@/services/Admin.service';
import ProductService from '@/services/Product.service';
import OrderService from '@/services/Order.service';

import Swal from 'sweetalert2';
export default {
  name: 'AddOrder',
  components: {
    Navigation,
    HeaderPage,
    FooterAdmin,
  },
  data() {
    return {
      userPhoneStatus: 1,
      paymentStatusItems: [
        { id: 1, text: 'ชำระมัดจำ' },
        { id: 2, text: 'ไม่ชำระมัดจำ' },
        { id: 3, text: 'ชำระเต็มจำนวน' },
      ],
      productTypeItems: ['สินค้าในสต็อคพร้อมส่ง', 'สินค้าพรีออเดอร์'],
      pageItems: [
        'M&M Toy Shop รับหาของเล่นจากต่างประเทศ',
        'Toy Secret LAB',
        'Ruby Toys',
        'Shock A Toys: โมเดลนำเข้าจากต่างประเทศ',
        'Exclusive Toys',
      ],
      customerItems: [],
      oldCustomerObj: null,
      adminItems: [],
      productItems: [],

      valid: true,
      adminRule: [(v) => !!v || 'กรุณาเลือกแอดมินที่ทำรายการ'],
      oldPhoneRule: [(v) => !!v || 'กรุณาเลือกเบอร์โทรศัพท์ลูกค้า'],
      newPhoneRule: [(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์ลูกค้า'],
      facebookRule: [(v) => !!v || 'กรุณากรอกชื่อจริงของลูกค้า'],
      productRule: [(v) => !!v || 'กรุณาเลือกสินค้า'],
      priceRule: [(v) => !!v || 'กรุณาระบุราคา'],
      quantityRule: [(v) => !!v || 'กรุณาระบุจำนวน'],
      productTypeRule: [(v) => !!v || 'กรุณาเลือกประเภทสินค้า'],
      paymentStatusRule: [(v) => !!v || 'กรุณาเลือกสถานะการชำระ'],
      depositRule: [(v) => !!v || 'กรุณากรอกยอดมัดจำ'],
      costRule: [(v) => !!v || 'กรุณากรอกมูลค่ารวมออเดอร์'],

      order: {
        admin_id: null,
        page: '',
        customer_id: null,
        phone: null,
        facebook_name: '',
        products: [
          { product_id: null, price: 0, quantity: 1, product_type: '' },
        ],
        payment_status_id: null,
        deposit: 0,
        outstand_balance: 0,
        cost: 0,
        remark: '',
        created_date: '',
      },

      loading: false,
    };
  },
  created() {
    this.getAdmins();
    this.getCustomers();
    this.getProducts();
  },
  methods: {
    addProduct() {
      const product = {
        product_id: null,
        price: 0,
        quantity: 1,
        product_type: '',
      };
      this.order.products.push(product);
    },
    delProduct() {
      this.order.products.pop();
    },
    handleOutstandBalance() {
      if (this.order.payment_status_id === 1) {
        if (this.order.cost > 0 && this.order.deposit > 0) {
          this.order.outstand_balance = this.order.cost - this.order.deposit;
        } else {
          this.order.outstand_balance = 0;
        }
      }
      if (this.order.payment_status_id === 2) {
        this.order.outstand_balance = this.order.cost;
        this.order.deposit = 0;
      }
      if (this.order.payment_status_id === 3) {
        this.order.deposit = 0;
        this.order.outstand_balance = 0;
      }
    },
    async getCustomers() {
      try {
        const response = await CustomerService.findAll();
        this.customerItems = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    selectOldCustomer() {
      this.order.customer_id = this.oldCustomerObj.id;
      this.order.facebook_name = this.oldCustomerObj.facebook_name;
    },
    async getAdmins() {
      try {
        const response = await AdminService.findAll();
        this.adminItems = response.filter(
          (admin) => admin.username !== 'bomdev'
        );
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async getProducts() {
      try {
        const response = await ProductService.findAll();
        this.productItems = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    clearForm() {
      this.oldCustomerObj = null;
      this.order.admin_id = null;
      this.order.page = '';
      this.order.customer_id = null;
      this.order.phone = null;
      this.order.facebook_name = '';
      this.order.products = [
        { product_id: null, price: 0, quantity: 1, product_type: '' },
      ];
      this.order.payment_status_id = null;
      this.order.deposit = 0;
      this.order.outstand_balance = 0;
      this.order.cost = 0;
      this.order.remark = '';
      this.order.created_date = '';
    },
    async submitAddOrder() {
      if (this.$refs.form.validate()) {
        this.order.deposit = Number(this.order.deposit);
        this.order.cost = Number(this.order.cost);
        try {
          const response = await OrderService.insert(this.order);
          this.clearForm();
          Swal.fire({
            position: 'bottom-end',
            toast: true,
            icon: 'success',
            title: response.msg,
            showConfirmButton: false,
            timer: 3000,
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: error.msg,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.outstand-balance {
  font-size: 24px;
  color: #fff;
  margin-top: 6px;
  white-space: nowrap;
}

.datetime-picker-input {
  background: #fff;
  display: block;
  height: 38px;
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  outline: none;
  width: 100%;
}
</style>
