import axios from 'axios';
import authHeader from './auth-header';
class Product {
  insert(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/management/product', body, {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  findAll() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get('/management/products', {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  findOne(name) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`/management/product?name=${name}`, {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  update({ product_id, product_name, price, stock }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.patch(
          `/management/product?id=${product_id}&name=${product_name}&price=${price}&stock=${stock}`,
          null,
          {
            headers: authHeader(),
          }
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  destroy(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.delete(`/management/product?id=${id}`, {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }
}
export default new Product();
